.customHeightFix {
  height: 680px;
}

.customHeightFix div button {
  width: 100%;
}

.cardSubHeader {
  background-color: #d4d4d4;
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
  margin: 0px 0px 10px 0px;
  color: black;
}
