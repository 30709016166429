.consecSelectWidth {
  width: 100%;
  height: 37px;
}

.green {
  color: green;
}

.black {
  color: black;
}
