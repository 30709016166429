.alignDeviceText {
  padding-left: 150px;
}

.table.ui.celled.single.line.sortable.striped.compact.center.aligned.table {
  padding: 2px !important;
}
.thead tr th {
  padding: 2px !important;
}
.tbody tr td {
  padding: 2px !important;
}

.ui.table thead tr > th,
.ui.celled.table tr td,
.ui.celled.table tr th {
  padding: 2px !important;
}

.deviceCodeContainer {
  padding: 10px;
  border: 2px solid grey;
}

.codeFieldLable {
  display: block;
  margin: 10px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
  width: 8%;
}

.container {
  -webkit-overflow-scrolling: touch;
  outline: none;
}

.anchorButton {
  float: right;
  background-color: #ffffff;
  border: none;
  // color: white;
  padding: 0px 8px 0px 8px;
  text-align: center;
  text-decoration: none;
  font-size: 15px;
  /* margin: 8px 14px 0px 17px; */
  border-radius: 25px;
  border-style: solid;
  cursor: pointer;
  /* border-color: #0dbbac; */
}

.cardHeader {
  background-color: #d4d4d4 !important;
}

.cardsContainer {
  padding: 25px 0px 50px 3px;
}

.ReactModal__Content {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  width: 60pc;
  height: auto;
  bottom: auto;
  border: 5px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.dropdownLabel {
  display: block;
  margin: 0 0 0.28571429rem 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
}
