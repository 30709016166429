.fieldLable {
  display: block;
  margin: 10px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
}
.customHeightFix {
  height: 660px;
}

.customHeightFix div button {
  width: 100%;
}

.customWidthFix {
  width: 55%;
}
.formGroupContainer {
  padding: 5px;
}

.error {
  color: red;
  white-space: nowrap;
}

.successMsg {
  color: green;
  white-space: nowrap;
}

.gridCustomRow {
  padding-top: 0px !important;
}

.gridCustomRowPadding {
  padding-left: 10px !important;
}
.customSelectWidth {
  max-width: 12em !important;
  margin: -30px 0px 1px 16px !important;
}

.customInputWidth {
  max-width: 12em !important;
}
.customBooleanWidth {
  max-width: 12em !important;
  margin: 2px 2px 2px 18px !important;
}
.customRadioButton {
  top: -30px;
}

.customTextArea {
  width: auto !important;
}

.noteText {
  font-weight: 500;
  font-style: italic;
  text-align: center;
  color: red;
}

.addDisabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.3;
}
.editableTextArea {
  -moz-appearance: textfield-multiline;
  -webkit-appearance: textarea;
  border: 1px solid gray;
  height: 130px;
  overflow: auto;
  padding: 2px;
  resize: both;
  width: 400px;
  color: black;
}
.adjustLeftMargin {
  margin-left: 318px !important;
}
