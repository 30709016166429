.codeFieldLable {
  display: block;
  margin: 10px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
  width: 8%;
  white-space: nowrap;
}
