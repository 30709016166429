.pushNavLink {
  margin-right: 70px !important;
}
.domZero {
  padding: 0 !important;
  border: 0 !important;
}
.padding {
  padding: 0 !important;
}
.marginlr {
  margin-left: 10px;
  margin-right: 10px;
}
.ulist {
  display: flex;
  margin: 10px;
  list-style: none;
}
