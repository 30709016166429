.cardsContainer {
  padding: 35px 0px 50px 8px;
}

.fieldContainer {
  padding: 20px 0px 30px 5px;
}

.expressionContainer {
  padding: 10px;
  border: 2px solid grey;
}

.customWidthFix {
  width: 50%;
}

.inValidExpression {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.3;
}

.cardHeader {
  background-color: #d4d4d4 !important;
}

// .noteText {
//   font-weight: 500;
//   font-style: italic;
// }
