.login {
  padding-top: 8%;
  margin: 0 auto;
}
.loginLogo {
  background-color: #0082ac;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 5px;
}

.login form {
  margin: 0 auto;
  padding: 40px 20px;
  max-width: 320px;
  background-color: #5f9c7f42;
}

.btnColor {
  background-color: #0082ac;
  color: #ffffff;
  opacity: 1;
  border: 0;
}

.forgotPW {
  color: #007bff;
  float: right;
  margin-top: 10px;
}

.shadow {
  -webkit-box-shadow: 3px 3px 5px 6px #6c757d6e; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 3px 3px 5px 6px #6c757d6e; /* Firefox 3.5 - 3.6 */
  box-shadow: 3px 3px 5px 6px #6c757d6e; /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}
