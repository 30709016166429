.radioBoxStylingContent * {
  display: inline-block;
}

.radioBoxStylingContent div:first-child {
  margin-right: 2% !important;
}

.ui.form .field > .selection.dropdown,
.inputSelectStyling {
  position: relative;
  bottom: 8px;
}
.contentAppendDiv {
  border: 1px solid #ccc;
  width: 200px;
  height: 100px;
  margin: 3px 24% 0px 0%;
  background: #fff;
  padding: 5px;
  position: relative;
  float: right;
  bottom: 63px;
}
.versionCssContentStyling {
  display: inline-block;
}

.customGridRow{
  margin: 10px 0px 4px 3px;
}

.addDisabled {
  cursor: not-allowed !important;
  pointer-events: none !important;
  opacity: 0.3 !important;
}

.customFileFix{
margin: 0px 0px 12px 2px;}

.containerStyleMain {
  width: 96vw;
  margin: 5px 1% 0% 2%;
  padding: 5px;
  box-shadow: 0px 0px 2px 3px #9e9e9e;
  background-color: #ececec;
 
}

.toggleContent {
  cursor: pointer;
  border-bottom: solid 1px #f2f2f2;
  border-left: solid 1px #f2f2f2;
  border-right: solid 1px #f2f2f2;
  padding: 8px;
  border-radius: 0 0 5px 5px;
  font-size: 14px;
  background-color: #ccdce2;
}

.matched{
  color: green;
}

.notMatched{
  color: red;
}