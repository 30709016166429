.fieldLable {
  display: block;
  margin: 10px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
  white-space: nowrap;
}

.alignWidth{
  width: 40% !important;
  padding-top: 10px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 600;
}
