.height {
  height: auto;
}
.cardHeader {
  background: #d4d4d4 !important;
}
.modelCards {
  width: 100%;
  margin: 10px;
}

.ReactTable .rt-tbody {
  height: 11pc !important;
}

.fieldLable {
  display: block;
  margin: 10px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
  white-space: nowrap;
}
