.modelCards {
  width: 25% !important;
}

.deviceCustomHeightFix {
  height: 500px !important;
}

.deviceCustomWidthFix {
  width: 600px !important;
}

.cardHeader {
  background-color: #d4d4d4 !important;
}

.cards {
  padding: 0px 5px;
}

.cardSubHeader {
  background-color: #d4d4d4;
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
  margin: 0px 0px 10px 0px;
  color: black;
}
// .deviceCustomWidthFix {
//   width: 400px !important;
// }
