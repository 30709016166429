body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'proxima-nova', Arial, Verdana, Sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #5f6062;
}

.App {
  background-color: #ececec;
  width: 100vw;
  min-height: 100vh;
}

.containerStyle {
  width: 95vw;
  margin: 5px 1% 0% 2%;
  padding: 5px;
  box-shadow: 0px 0px 2px 3px #9e9e9e;
  background-color: #ececec;
}

.ReactTable {
  background-color: #e6f1f5;
  color: black;
}

.ReactTable .rt-noData {
  padding: 4px;
  top: 132px !important;
  left: 52% !important;
  color: #000000cc;
  border: 1px solid #6b5858;
}

.ReactTable .rt-thead .rt-tr {
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #c1bbbb;
  background-color: #ccdce2;
}

.ReactTable .rt-thead.-filters .rt-th {
  border-right: 2px solid #d6d6d6;
}

.ReactTable .rt-tbody .rt-td {
  border-right: 2px solid #d6d6d6;
}

.ReactTable .rt-tbody {
  line-height: 14px;
}

.ReactTable .rt-table {
  font-size: 12px;
}

.ReactTable .rt-thead.-filters .rt-th input {
  height: 37px;
}

.ReactTable .-pagination .-pageJump input {
  height: 20px;
}

.ui.secondary.pointing.menu .active.item:hover {
  border-color: #21ba45;
}

.ReactTable .react-datetime-picker {
  display: block;
  position: relative;
  background-color: #ffffff;
  .react-datetime-picker__button {
    height: 0px;
    padding: 0;
  }
}

.toggleHeader {
  cursor: pointer;
  border: solid 1px #f2f2f2;
  padding: 8px;
  background-color: #0082ac;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}

.toggleContent {
  cursor: pointer;
  border-bottom: solid 1px #f2f2f2;
  border-left: solid 1px #f2f2f2;
  border-right: solid 1px #f2f2f2;
  padding: 8px;
  border-radius: 0 0 5px 5px;
  font-size: 14px;
  background-color: #ccdce2;
}

a:focus,
a:hover {
  color: #034a59;
  text-decoration: none;
}

.daUfMa a,
.daUfMa .navbar-nav,
.daUfMa .navbar-light .nav-link {
  color: #efefef;
  font-size: 1rem;
  font-weight: bold;
}

// General color for SCIEX Buttons/ Panel Headers
.bgBlue {
  background-color: #ccdce2 !important;
}

.historic {
  padding: 0.75em 1em 2em 1em;
  font-size: 18px;
}

.navbarstyle .ui.menu .item > img:not(.ui) {
  width: 90px;
  margin-right: 70px;
}

.navbarstyle * {
  background-color: #0082ac !important;
  color: #fff !important;
  font-size: 16px;
  margin-bottom: 0;
}

.Jsonformat {
  background-color: white;
  color: black;
  font-weight: 600;
  font-size: large;
}

/* Rules Page CSS Styling */

.ui.menu {
  border: none;
}

.ui.dropdown {
  padding: 12px 8px 0px 8px;
}

.PhoneInputCountryIcon {
  width: 40px !important;
  height: 26px !important;
}

a.active {
  background-color: #21ba45 !important;
}

.ui.menu .item > .label {
  font-size: 15px;
  padding: 0px;
  width: 50px;
}

.ui.selection.dropdown {
  margin: 0;
}

.addNewMainContainer {
  padding: 10px;
}

.iconLable {
  margin: 15px;
}

.fluid .card .cardHeader {
  background-color: #d4d4d4;
}

.ui.grid {
  display: block;
  padding: 0;
}

span .marginRight {
  margin-right: 20px;
}

.cards {
  padding: 0px 5px;
}

/*Navigation Styles*/
.navbarstyle {
  .menu div.dropdown .dropdown-menu a:first-child {
    border-bottom: 2px solid #000 !important;
    margin-bottom: 2px !important;
  }
  div.dropdown {
    position: relative;
    bottom: -1px;
  }
  .dropdown button {
    margin-top: 2px;
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .dropdown-menu a.item,
  .dropdown a,
  .ui.secondary.pointing.menu .active.item {
    margin-bottom: 1px;
  }
  .dropdown button:focus {
    outline: none !important;
  }
  .btn-primary:focus,
  .btn-primary.focus {
    border-color: none;
    box-shadow: none;
  }
  .dropdown button i {
    position: relative;
    top: 1px;
    left: 3px;
  }
  .right.menu .item a {
    background: none !important;
  }
  div.dropdown.active,
  div.dropdown.active button i,
  div.dropdown.activeItem,
  div.dropdown.activeItem button,
  div.dropdown.activeItem button i,
  div.dropdown.active button {
    background: #21ba45 !important;
  }
  div.dropdownmenuHighlight.active a {
    background: #21ba45 !important;
    margin-bottom: 0px !important;
    border-bottom: 2px solid #1b1c1d !important;
  }
}

/*Contents Styling*/
#addNewContentContainer .ui.form .field > label {
  display: inline-block;
  margin-right: 10px;
}

.ui.celled.table tr td,
.ui.celled.table tr th {
  padding: 2px !important;
  word-wrap: break-word;
  white-space: pre-line;
  text-align: left;
}
.ui.divided.items,
table.ui.celled.single.line.sortable.striped.compact.center.aligned.table {
  padding: 2px 0px 0px 0px !important;
  padding-top: 20px !important;
  border: none;
  position: relative;
  top: 18px;
  table-layout: fixed;
}

//rules
.modelCards {
  width: 25%;
  width: 285px;
}

.modelCardsDeviceCode {
  width: 25%;
}

// File upload page styles
.fileUploadStyle {
  padding: 10px !important;
}

.multiCheckBox div button {
  width: 54pc;
}
.multiCheckBox div {
  z-index: 99;
}

.multiCheckBox div button span,
.multiCheckBox div button span svg {
  float: right;
}

.multiCheckDiv div button {
  width: 130px;
}
.multiCheckDiv div button span,
.multiCheckDiv div button span svg {
  float: right;
}

.exportButton {
  float: right;
  background: #0082ac;
  color: white;
  border: none;
  font-size: 15px;
  height: 30px;
}

.refreshButton {
  float: right;
  background: #0082ac;
  color: white;
  border: none;
  font-size: 15px;
  height: 30px;
  margin-right: 6px;
}

.addDisabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.3;
}

.ui.form input[type=text]{
  overflow: hidden;
  text-overflow: ellipsis;
  border: #000;
  border-width: 1px;
  border-style: solid;
}

.element::-webkit-input-placeholder {
  font-weight: bold;
}
