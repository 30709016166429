.modelCards {
  width: 25% !important;
}

.deviceCustomHeightFix {
  height: 300px !important;
}

// .deviceCustomWidthFix {
//   width: 450px !important;
// }

.cardHeader {
  background-color: #d4d4d4 !important;
}

.cards {
  padding: 0px 5px;
}

.customHeightFix {
  height: 500px;
}

.customHeightFix div button {
  width: 100%;
}
